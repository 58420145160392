import { AllRates } from './'

const common = {
    unregisteredAssetLimit: 100000,

    assetLimitLowRate: .1,
    assetLimitHighRate: .2,

    areaSupportedBase: 65,
    areaSupportedPerAdditionalResident: 20,

    housingExpenseRateIncreasePerChild: .05,

    benefitUpperLimitRateIncreaseAtLeastFourChildren: .25,
}

export const boligydelse: AllRates = {
    ...common,
    assetLimitLow: 1011700,
    assetLimitHigh: 2023700,

    housingExpenseBaseLimit: 107800,
    housingExpenseBonus: 8100,
    housingExpenseHeatingOverRentDeduction: 103.25,
    housingExpenseHotWaterOverRentDeduction: 32.75,
    housingExpenseElectricityOverRentDeduction: 78,
    housingExpenseWaterChargeSupplement: 17.25,
    housingExpenseWaterDrainageChargeSupplement: 25,
    housingExpenseFullMaintenanceSupplement: 78,
    housingExpensePartialMaintenanceSupplement: 38.75,
    housingExpenseHeatingSupplement: 39.5,

    benefitHousingExpenseRate: .75,
    benefitHouseholdIncomeRate: .225,
    benefitHouseholdIncomeBaseLimit: 192200,
    benefitHouseholdIncomeIncreasePerChild: 50600,

    benefitBaseUpperLimit: 56892,
    benefitLowerLimit: 4176,
    benefitMinSelfPaymentRateOfIncome: .11,
    benefitMinSelfPayment: 20300,
}

export const boligsikring: AllRates = {
    ...common,
    assetLimitLow: 884100,
    assetLimitHigh: 1768300,

    housingExpenseBaseLimit: 94200,
    housingExpenseHeatingOverRentDeduction: 90.25,
    housingExpenseHotWaterOverRentDeduction: 28.5,
    housingExpenseElectricityOverRentDeduction: 68,
    housingExpenseWaterChargeSupplement: 15,
    housingExpenseWaterDrainageChargeSupplement: 21.75,
    housingExpenseFullMaintenanceSupplement: 68,
    housingExpensePartialMaintenanceSupplement: 33.75,
    housingExpenseHeatingSupplement: 34.5,

    benefitHousingExpenseRate: .6,
    benefitHouseholdIncomeRate: .18,
    benefitHouseholdIncomeBaseLimit: 167900,
    benefitHouseholdIncomeIncreasePerChild: 44200,

    benefitBaseUpperLimit: 49716,
    benefitLowerLimit: 3648,
    benefitMaxRateOfExpenseNoChildren: .15,
    benefitMinSelfPayment: 28300,
}